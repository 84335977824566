import { Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { crmSafeListByIDs } from 'common-module/list';
import { map, Observable } from 'rxjs';

import { CreateEventDto } from './create-event.dto';
import { EventModel } from './event.model';

@Injectable({ providedIn: 'root' })
export class EventsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'events',
  })
  private endpoint!: CrmEndpoint<EventModel>;

  list(params?: CrmDictionary) {
    return this.endpoint.list({ params });
  }

  listAll(params?: CrmDictionary) {
    return this.endpoint.listAll({ params });
  }

  listSafe(ids: string[]) {
    return crmSafeListByIDs(
      (params) => this.list(params).pipe(map(({ data }) => data)),
      ids,
      20,
    );
  }

  create<Resp>(body: CreateEventDto) {
    return this.endpoint.create(body) as Observable<Resp>;
  }
}
